<template>
  <div>
    <!-- <RegistrationHeader icon="mdi-currency-usd" title="Cost Breakdown"></RegistrationHeader> -->
    <PageHeader title="Cost Breakdown" :subtitle="ymm"></PageHeader>

    <v-card color="white" flat class="mb-10">
      <v-card-title class="mainBlack--text">
        <div class=" d-flex flex flex-column flex-sm-row  justify-sm-space-between">
          <div class="gotham-font">Total Amount Due</div>
          <h1 class="mt-4 mt-sm-0">{{ total | currency }}</h1>
        </div>
      </v-card-title>
      <!-- <v-card-text class="mainBlack--text gotham-font">The details below explain how this amount was determined</v-card-text> -->
    </v-card>

    <v-card color="cardBackground">
      <v-card-text>
        <div class="text-body-1">Below is how your registration cost was determined</div>
        <div class="mt-4 mb-4 cost-grid" :class="$vuetify.breakpoint.smAndDown ? 'small' : 'large'">
          <div class="pa-2">Name</div>
          <div class="pa-2 border top">{{ name }}</div>

          <div class="pa-2">Vehicle</div>
          <div class="pa-2 border">{{ ymm }}</div>

          <div class="pa-2">
            VIN<v-tooltip bottom max-width="200" close-delay="300">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-4 mainRed--text">mdi-information</v-icon>
              </template>
              <span>The Vehicle Identification Number, 17 characters (numbers & letters) that act as a unique identifier for the vehicle</span>
            </v-tooltip>
          </div>
          <div class="pa-2 border">{{ vin }}</div>

          <div class="pa-2">Date of Sale</div>
          <div class="pa-2 border">{{ dateOfSale }}</div>

          <div class="pa-2">
            Taxable Amount
            <v-tooltip  bottom max-width="200" close-delay="300">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-4 mainRed--text">mdi-information</v-icon>
              </template>
              <span>Must include Trade-in and Rebate</span>
            </v-tooltip>
          </div>
          <div class="pa-2 border">{{ taxableAmount | currency }}</div>

          <div class="pa-2">Sales Tax</div>
          <div class="pa-2 border">{{ salesTax | currency }}</div>

          <div class="pa-2">County Tax (plus $200 EV fee)</div>
          <div class="pa-2 border">{{ countyTax | currency }}</div>

          <div class="pa-2">Service Fee</div>
          <div class="pa-2 border">{{ serviceFee | currency }}</div>

          <div class="pa-2">
            Late Fee
            <v-tooltip  bottom max-width="200" close-delay="300">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-4 mainRed--text">mdi-information</v-icon>
              </template>
              <span>If there are no delays, this amount will be refunded by check when the plates are in the mail</span>
            </v-tooltip>
          </div>
          <div class="pa-2 border">{{ lateFee | currency }}</div>

          <div class="mt-4">
            <v-divider></v-divider>
          </div>
          <div class="mt-4" v-if="$vuetify.breakpoint.mdAndUp">
            <v-divider></v-divider>
          </div>

          <div class="mt-2 mb-4">
            <v-divider></v-divider>
          </div>
          <div class="mt-2 mb-4" v-if="$vuetify.breakpoint.mdAndUp">
            <v-divider></v-divider>
          </div>

          <div class="pa-2 ">TOTAL DUE</div>
          <div class="pa-2 border top">{{ total | currency }}</div>

        </div>

        <div class="mt-10 text-body-1 mainBackground px-3 py-3 rounded ">
          * If you have any questions about the costs on this page, please send an email to
          <a target="_blank" href="mailto:register@thetitlegirl.com">register@thetitlegirl.com</a> or call us at 214-997-6005. Any
          overcalculations will be reconciled upon completion once paid to county and paid back to you.
        </div>
      </v-card-text>

 <v-card v-if="this.$store.state.registration.app.Status!=completedStatus">    
      <v-card-actions>
        <section class="d-flex pa-3 mt-8" style="width: 100%">
          <BackButton text="cancel"></BackButton>
          <v-btn large color="success" :to="{ name: 'app.registration.paymentDetails' }" class="ml-auto">
            Select Payment Method
          </v-btn>
        </section>
      </v-card-actions>
 </v-card>
    </v-card>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  directives: {
    mask,
  },
  components: {
    // RegistrationHeader: () => import('@/components/app/RegistrationHeader'),
    PageHeader: () => import('@/components/app/PageHeader'),
    // CostItem: () => import('./CostItem.vue'),
  },
  created() {
    const regId = this.$route.params.regId;
    this.setupData(regId);
  },
  data: () => ({
    vin: null,
    name: null,
    zipCode: null,
    county: null,
    dateOfSale: null,
    taxableAmount: null,
    salesTax: null,
    countyTax: null,
    serviceFee: null,
    lateFee: null,
    total: null,
    ymm: null,
    completedStatus: 'Transaction Completed',

  }),
  computed: {},
  methods: {
    setupData(regId) {
      this.vin = this.$store.state.registration.app.VIN;
      this.name = this.$store.state.registration.app.CustomerName;
      this.zipCode = this.$store.state.registration.app.ZipCode;
      this.county = this.$store.state.registration.app.County;
      this.dateOfSale = this.$store.state.registration.app.TitleDOS;
      this.taxableAmount = this.$store.state.registration.app.TaxableAmount;
      this.salesTax = this.$store.state.registration.app.SalesTax;
      this.countyTax = this.$store.state.registration.app.CountyFee;
      this.serviceFee = this.$store.state.registration.app.ServiceFee;
      this.lateFee = this.$store.state.registration.app.LateFee;
      this.total = this.$store.state.registration.app.RegistrationAmount;
      this.ymm = this.$store.state.registration.app.yearMakeModel;
      this.$store
        .dispatch('costBreakdown/pageViewed', regId)
        .then(res => {
          this.$store.commit('snackmsg/show', {
            text: 'Your cost breakdown has been reviewed',
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.table {
  width: 100%;
  table-layout: fixed;
}
table,
th,
td {
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-collapse: collapse;
  padding: 5px 10px;
}
.table tr td:nth-child(1) {
  max-width: 300px;
  white-space: nowrap;
}
.cost-grid {
  display: grid;
}
.cost-grid.small {
  grid-template-columns: 1fr;
}
.cost-grid.small div:nth-child(odd) {
  margin-top: 10px;
}
.cost-grid.small .border {
  border: 1px solid grey;
}
.cost-grid.large {
  grid-template-columns: 250px 1fr;
}
.cost-grid.large .border { 
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: grey;
}
.cost-grid.large .border.top { 
  border-width: 1px;
}
</style>
